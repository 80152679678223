(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:LicenseTeamPerson
   *
   * @description
   *
   */
  angular
      .module('neo.services')
      .factory('LicenseTeamPerson', LicenseTeamPerson);

  function LicenseTeamPerson($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/teams/:teamId/licences/:licenseId', {
      personId: '@_teamId',
      licenseId: '@_licenseId'
    }, {
      query: {
        method: 'GET',
        isArray: false
      }
    });
  }
}());
